<template>
  <div class="coreProduct" >
    <div class="sticky"><div class="modified"></div>
    <div class="title">五大核心产品</div>
    </div>
    <div class="swiperCore">
      <swiperCoverflow ref="swiperCoverflow">
        
      </swiperCoverflow>
      </div>
  </div>
</template>
<script>
import swiperCoverflow from "@/components/swiper-core.vue";

export default {
  name: "coreProduct",
  components: {
    swiperCoverflow,
  },
  data() {
    return {
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      //   this.height = window.innerHeight;
    });
  },
  methods:{
    toIndex(index){
    this.$refs.swiperCoverflow.toIndex(index)
    }
  }
};
</script>
<style lang="less">
.coreProduct {
    height: 1080px;
    background: url("../assets/home/bg.png");
    background-size: 100% 100%;


  .sticky {
    overflow: hidden;
    .title{
      margin: 150px 0 120px;
      font-size: 40px;
      font-weight: bold;
    }
  }
    .modified {
      position: absolute;
      top: 151px;
      right: 50px;
      width: 300px;
      height: 250px;
      background: url("../assets/home/modified1.png");
      background-size: 100% 100%;
    }
}
.swiperCore {
  width: 1500px;
  margin: 0 auto;
}
</style>