<template>
  <div class="example-3d">
    <swiper
      class="swiper"
      ref="mySwiper"
      :options="swiperOption"
      @slideTo="slideTo(0, 1000, false)"
    >
      <swiper-slide
        v-for="(items, index) in list"
        @click="change"
        :id="index"
        :key="index"
      >
        <div class="content">
          <div class="topImg"><img :src="items.imgUrl" alt="" /></div>
          <h2>{{ items.title }}</h2>
          <div class="text">{{ items.content }}</div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-3d-coverflow",
  title: "3D Coverflow effect",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        // 自动切换
        autoplay:{
          disableOnInteraction: false,
        },
        // 切换速度
        speed: 600,
        // loop 模式 也就是当到最后一张时再点下一个就是第一张
        loop: true,
        effect: "coverflow",
        grabCursor: false,
        centeredSlides: true,
        slidesPerView: "auto",
        initialSlide: 2,
        coverflowEffect: {
          rotate: 0,
          stretch: 40,
          depth: 60,
          modifier: 2,
          slideShadows: false,
        },
        on: {
          click: (e) => {
            // this.change(e.target.getAttribute("id"));
          },
        },
      },
      list: [
        {
          id: 1,
          imgUrl: require("../assets/home/swiper1.png"),
          title: "政策提倡，大势所趋",
          content:
            "近年来，国家政策大力倡导体育赛线上化、数智化，各地企事业单位也纷纷积极响应并参与其中。在政企民的通力协作下，线上赛必将普及化、系统化。",
        },
        {
          id: 2,
          imgUrl: require("../assets/home/swiper2.png"),
          title: "民众心声，顺应市场",
          content:
            "全面小康建成，民众精神层面的需求日益高涨，健身、体育运动等备受关注，而不受时间、地点限制，互动性强、可选性高、更自由的线上运动更是受到广泛青睐。",
        },
        {
          id: 3,
          imgUrl: require("../assets/home/swiper3.png"),
          title: "打造闭环，助力转型",
          content:
            "受市场变化、用户需求多样性、疫情等因素影响，传统线下赛事运营公司面临转型困难、处境被动的难题。专业赛事SaaS系统，保留线下赛事需求的同时，创新性提供一站式线上赛事工具和服务，助力赛事运营商轻松转型，形成业务闭环。",
        },
        {
          id: 4,
          imgUrl: require("../assets/home/swiper4.png"),
          title: "降本增效，收益up！",
          content:
           "办一场线下赛事，需要流程管理、把控细节，投入人工成本高、策划周期长，常常消耗运营商更多的成本和精力。而线上赛事搭建便捷，成本低廉、方案可复制，做到“一人办一场赛事”，解放人力、财力，让业务收益快速提升！",
        },
        {
          id: 5,
          imgUrl: require("../assets/home/swiper5.png"),
          title: "花样赛事，彰显特色",
          content:
            "不同于线下赛事的常规模式，线上赛事拥有更多的可能性。丰富的赛事玩法、充满创意的趣味运动、更好结合文旅、教育等生态，线上赛事，让运营商拥有更多的发挥空间，能尽情彰显本地特色，对推动当地业态发展、响应全民运动等政策也有极大的助力！",
        },
      ],
    };
  },
  mounted() {},
  computed: {
    swiper: function () {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    change(e) {
      this.swiper.slideTo(e, 1000, false);
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="less" scoped>
.example-3d {
  width: 100%;
  height: 400px;
  padding-bottom: 50px;
}

.swiper {
  height: 100%;
  width: 100%;
  overflow: visible !important;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 420px;
    height: 560px;
    box-shadow: 0px 10px 30px 1px rgba(128,58,0,0.1);
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    background-color: #fff;
    background-position: center;
    background-size: cover;
  }
  .swiper-pagination {
    /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
    }
  }
  /deep/.swiper-slide{
    .content{
    opacity: 0.3;

    }
  }
 
  /deep/.swiper-slide-next{
 .content{
    opacity: 0.4;

    }

  }
   /deep/.swiper-slide-prev{
     
     .content{
    opacity: 0.4;

    }
  }
  .swiper-slide-active{
      color: #FD7A0D !important;
      .content{
    opacity: 1;

    }
  }
  .content{
      width: 100%;
      height: 100%;
      padding: 109px 54px 0;
      pointer-events:none;
      .topImg{
          width: 150px;
          height: 150px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
              width: 100%;
          }
      }
      h2{
          margin: 50px 0 24px;
          font-size: 26px;
      }
      .text{
          font-size: 14px;
          line-height: 30px;
          font-weight: 500;
          text-align: justify;
          text-indent: 2em;
      }
  }
 
}
</style>