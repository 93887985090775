let jobs = [{
        "id": 1,
        "time": "2022 - 9 - 1",
        "position": "APP产品经理",
        "type": "产品类",
        "year": "5-10",
        "address": "杭州·滨江·长河",
        "obligation":`岗位对应的App是一个软硬结合的运动健康类App，拥有智能硬件接入部分、纯软件功能、内容部分、社交以及电商部分。<br />1、负责公司软件产品的规划和落地，根据行业变化，市场变化，结合公司战略及业务实际情况系统规划产品的发展，包含需求分析，产品规划，功能设计并推动落地直至交付全过程；<br />2、全局统筹多产品线的闭环，包括智能硬件接入、软件功能、社交功能、电商功能等；<br />3、对用户全周期的管理，通过数据分析、用户画像挖掘商业价值，实现用户增长、价值增长。<br />4、实时关注市场竞品动态，进行数据调研和分析，结合行业发展的需求，提升产品在所属领域的市场竞争力；<br />5、对App核心指标负责，包括新增、活跃、留存等；<br />6、负责团队成员的日常管理，制定可量化的目标、考核制度。<br />【加分项】有电商、返利经验、对SaaS有一定了解`,
        "requirements": "1、本科及以上学习，5年以上互联网产品理经验；<br />2、对工具类App有深入的理解和打法，同时对智能硬件接入有一定的了解；<br />3、熟悉运动、健康、赛事行业；<br />4、具备全局思维，逻辑思维，业务抽象能力强，能快速产出高质量方案；<br />5、自驱力强，有思辨和换位思考能力，强烈的责任心与良好的团队协作意识。",
        "email": "",
        "phone": ""
    },
    {
        "id": 2,
        "time": "2022 - 9 - 1",
        "position": "SaaS平台产品经理 ",
        "type": "产品类",
        "year": "3-5",
        "address": "杭州·滨江·长河",
        "obligation": "1、负责研究公司B端平台产品的总体规划、功能特点、竞争优势、市场应用，凝练解决方案和商业模式，并对销售提供全面技术支撑；<br />2、主导智慧体育、活动赛事平台（销售系统、物联系统、账号权限、客服系统等）的建设及优化完善，助力销售及运营团队的工作开展，增强系统的业务赋能及提升运营效率；<br />3、以产品全生命周期管理为主线，统筹协调产品系统方案设计、质量、进度、成本、交付等各项关键任务和管理工作；<br />4、做好产品的版本规划管理与产品需求管理。定期组织召开项目评审会议，确认产品需求实现过程中的偏差，及时沟通并予以调整。",
        "requirements": "1、本科或本科以上学历，3-5年以上经验；<br />2、熟悉TOB/C平台型产品工作经验，扎实的产品能力，独立负责过产品线；<br />3、具备全局思维，逻辑思维，业务抽象能力强，能快速产出高质量方案；<br />4、自驱力强，有思辨和换位思考能力，强烈的责任心与良好的团队协作意识<br />【加分项】有SaaS相关经验优先。",
        "email": "",
        "phone": ""
    },
    {
        "id": 3,
        "time": "2022 - 9 - 1",
        "position": "前端开发工程师  ",
        "type": "技术类",
        "year": "1-3",
        "address": "杭州·滨江·长河",
        "obligation": "1、制定前端的标准和规范，并推广和应用，提高团队的开发效率，强力支撑业务发展；<br />2、规划前端团队的技术方向和长期目标，推进团队按目标进展落地，为团队内其他成员提供培训和指导；<br />3、对前端团队产出的质量和效率负责，熟知架构演进路线，优秀的培训指导和自学能力；<br />4、兼具移动端和Web端前端产品开发实施和管理经验；<br />5、会object-C或者Swift优先。",
        "requirements": "1、计算机相关专业，3年以上web前端开发经验；<br />2、熟悉ES6开发模式；<br />3、精通VUE、less，React，熟悉Node.js、weex、element-ui、antd，iView（View UI）；<br />4、精通使用uni-app；<br />5、能够熟练的设计和开发基于VUE的复杂web应用并有丰富实践经验；<br />6、熟练使用Git等版本控制工具；<br />7、具备良好的编程习惯及程序设计能力；<br />8、具备自主的学习能力和追求卓越的态度，具有良好的团队协作精神。",
        "email": "",
        "phone": ""
    },
    {
        "id": 4,
        "time": "2022 - 9 - 1",
        "position": "PHP高级工程师",
        "type": "技术类",
        "year": "3-5",
        "address": "杭州·滨江·长河",
        "obligation": "1、负责公司运营网站及产品的开发和维护；<br />2、参与公司网站、产品架构设计,不断优化系统结构和代码,解决技术难题；；<br />3、参与各系统、产品的需求分析、设计、测试等工作；<br />4、指导团队新成员的项目开发工作。",
        "requirements": "1、计算机、通信、电子、数学等专业毕业，至少3年以上PHP使用经验；<br />2、有PHP独自开发一个模块或功能的经验或能力,至少熟悉一种常用PHP开发框架（如ThinkPHP、CodeIgniter、Yii、Zend Framework等)；<br />3、熟悉MySQL、PostgreSQL、MS SQL Server、Oracle等至少一种数据库的使用,熟悉视图、存储过程、函数、触发器、外键、约束等特性及使用<br />4、熟悉Linux的安装及常用操作,熟悉其下Apache/Nginx/PHP/cron/防火墙/等常用服务的安装配置；<br />5、熟悉HTML、Javascript、CSS、jQuery等开发与调试；<br />6、具备良好的代码编写、注释说明等开发习惯；<br />7、有Fastadmin框架开发经验优先；<br />8、有Java、C++、C#、Python等开发经验优先；<br />9、有体育类app、电商平台开发经验优先；<br />10、有Workerman、Swoole应用开发经验优先。",
        "email": "",
        "phone": ""
    },
    {
        "id": 5,
        "time": "2022 - 9 - 1",
        "position": "creator cocos",
        "type": "技术类",
        "year": "3-5",
        "address": "杭州·滨江·长河",
        "obligation": "1、使用Cocos Creator 同时开发原生和Web游戏；<br />2、提出工程中编码设计的缺陷，优化游戏性能和表现；<br />3、跟踪相关游戏特性，主动与策划沟通，并提出需求建议 ；<br />4、协助后端进行页面及接口调试，实现产品功能和操作流程；",
        "requirements": "1、2年及以上的棋牌游戏开发经验，有SLOTS游戏开发经验者优先；<br />2、熟悉Cocos Creator及配套开发工具；<br />3、熟悉Socket，Http通信，熟悉JS编程语言<br />4、对系统（iOS或Android）原生开发有一定了解，有相关开发经验者优先；<br />5、热爱前端，工作积极性高, 具有极强的快速学习能力和良好的团队合作精神；",
        "email": "",
        "phone": ""
    },
    {
        "id": 6,
        "time": "2022 - 9 - 1",
        "position": "unity开发工程师",
        "type": "技术类",
        "year": "3-5",
        "address": "杭州·滨江·长河",
        "obligation": "1、使用Unity3D进行游戏客户端核心模块开发，负责游戏核心架构的搭建以及技术攻关；<br />2. 与策划、美术沟通沟通制作标准，控制资源性能；<br />3、研发所需的各类工具，编辑器等内容的再开发以及调整优化；<br />4、针对各个发布平台进行游戏性能优化。",
        "requirements": "1、2年以上Unity3D开发经验，至少有一款完整的成功的大型游戏项目开发经验；    <br />2、熟悉Unity3D引擎架构设计，熟悉Lua/C#/Python某一种或者多种语言；熟悉Unity3D常用插件，熟悉Unity3D源码者优先；<br />3、精通3D图形学原理，精通U3DShader编程，有自研引擎制作经验者优先；<br />4、热爱游戏，有创业精神，有良好的团队精神、沟通及协作能力；<br />5、有完整从开发到运营上线稳定的产品经验者优先。",
        "email": "",
        "phone": ""
    },
    {
        "id": 7,
        "time": "2022 - 9 - 1",
        "position": "laya游戏开发工程师 ",
        "type": "技术类",
        "year": "3-5",
        "address": "杭州·滨江·长河",
        "obligation": "1、Layabox3D游戏开发经验优先；<br />2、 熟悉数据结构、 面向对象开发和设计， 拥有良好的代码风格；<br />3、 有责任心， 良好的沟通能力和团队协作能力， 善于独立解决问题的能力；<br />4、 数学基础扎实， 熟悉3D游戏相关的基本算法， 有大型MMO网络游戏开发经验者优先考虑； ",
        "requirements": "",
        "email": "",
        "phone": ""
    },
    {
        "id": 8,
        "time": "2022 - 9 - 1",
        "position": "高级UI设计师 ",
        "type": "运营类",
        "year": "3-5",
        "address": "绍兴·越城区",
        "obligation": "1、负责公司WEB端、PC端或手机端产品的UI设计；<br />2、参与产品前期用户需求研究、分析产品应用行业的设计流行趋势；<br />3、独立完成UI相关制作，能根据产品的设计思路设计相应配套的UI、有组件和规范能力；<br />4、对产品用户体验设计富有激情、创造力和责任感；<br />5、做好与研发同事的工作对接。<br />【加分项】游戏ui、ip设计、3d。",
        "requirements": "1、本科及以上学历，平面设计、美术设计、计算机等相关专业者优先；<br />2、3年年以上UI设计经验；<br />3、精通UI工作岗位的技能、方法、工具和流程；<br />4、熟练掌握 各类常用设计软件或工具；<br />5、具有极强的界面设计功底和色彩把握能力及设计创意能力。<br />6、具备较好的用户体验设计意识，并善于将用户体验设计应用于工作中；<br />7、思路严谨，可以独立完成整个设计过程；<br />8、具备良好的团队协作精神，极强的责任心。",
        "email": "",
        "phone": ""
    },
    {
        "id": 9,
        "time": "2022 - 9 - 1",
        "position": "活动策划主管 ",
        "type": "运营类",
        "year": " 1-3",
        "address": "绍兴·越城区",
        "obligation": "1、与甲方沟通了解客户活动需求和活动要求，编写活动策划方案；<br />2、根据活动策划方案，编写活动预算表和活动物料表；<br />3、跟进采买活动物料，跟进物料情况；<br />4、与设计部门沟通所需设计物品和下单制作时间节点；<br />5、协助跟进现场活动运营情况，保证活动运营顺畅。",
        "requirements": "1、大专以上学历，会展策划、广告学等相关专业；<br />2、有相关经验者可放宽要求；<br />3、有良好的沟通协调能力；<br />4、能接受加班和一定的压力。",
        "email": "",
        "phone": ""
    },
    {
        "id": 10,
        "time": "2022 - 9 - 1",
        "position": "活动市场营销",
        "type": "运营类",
        "year": " 3-5",
        "address": "绍兴·越城区",
        "obligation": "1、参与项目前期创意会、头脑风暴，并根据讨论结果梳理策划方案，具有演讲能力，能够承接赛事活动业务；<br />2、负责线上线下活动业务开拓，完成个人业绩指标及年度团队指标；<br />3、负责跟进并维护客户关系，对客户满意度负责，深挖客户需求并扩大合作范围；<br />4、了解活动报价体系，能与客户进行价格磋商；<br />5、了解标书制作并组织资源完成招投标工作；<br />6、跟进项目整体进度，确保项目按照既定标准落地完成，完成收款工作；<br />【加分项】有电信移动相关工作经验，有项目、政府资源优先。",
        "requirements": "1、市场营销相关专业，熟悉赛事活动行业；<br />2、3年以上客户开拓经验；<br />3、具备市场开拓及谈判能力，能接受短期出差；<br />4、具备客户关系维护及协调能力，为人正直，善于沟通；<br />5、熟悉项目招投标流程及独立制作标书能力；<br />6、性格开朗、吃苦耐劳、工作热情度高、能快速融入团队、传递正能量。",
        "email": "",
        "phone": ""
    },
    {
        "id": 11,
        "time": "2022 - 9 - 1",
        "position": "SaaS销售 ",
        "type": "销售类",
        "year": " 3-5",
        "address": "杭州·滨江·长河",
        "obligation": "1、能独立通过各种渠道开发客户资源，对业绩考核目标达成负责；<br />2、负责制定SaaS产品的销售策略及销售方案，拓展生态合作伙伴，整合外部资源，不断优化基于商业模式的时长拓展业务模式；<br />3、及时调查、分析和反馈客户、竞品、生态合作伙伴的信息，为公司战略、产品、服务、政策等优化提供支持及建议；<br />4、与公司各部门配合，维护客情关系，协助处理客户的反馈、投诉和建议，提高客户使用满意度。",
        "requirements": "1、专科以上学历，3年以上的软件销售经验；<br />2、熟悉SaaS行业或B2B的业态，具有SaaS销售经验者有限；<br />3、敏锐的商业洞察力和谈判力，能根据销售的关键环节进行及时准确的行对，对B端有自己的方法论；<br />4、强烈自驱力，且目标感强，能够快速执行拿结果；<br />5、具备较强的沟通表达能力以及客户服务意识。",
        "email": "",
        "phone": ""
    },
    {
        "id": 12,
        "time": "2022 - 9 - 1",
        "position": "人事行政主管  ",
        "type": "行政类",
        "year": "1-3",
        "address": "杭州·滨江·长河",
        "obligation": "1、负责组织制定行政管理规章制度及推动落地，为公司内部提供优秀的行政服务支持；<br />2、 负责各部门落地员工关怀、 员工活动等， 协助建设企业文化和组织氛围；<br />3、 负责公司内部办公环境、 安全保卫、 保洁、 绿植等日常管理的组织落实；<br />4、 负责主持部门内部的建设工作， 制定本部门人员的工作绩效考核方案及相关指标的制定， 并持续改善；<br />5、 负责协助总部做好项目部招聘工作；<br />6、 负责人员出入场管理；<br />7、 领导布置的其他任务。 ",
        "requirements": "",
        "email": "",
        "phone": ""
    }
]
let packages = [{
        title: "赛事模板特权",
        list: [{
                name: "赛事模板多样性",
                version1: "5个（价值5万）",
                version2: "无上限",
                version3: "无上限"
            },
            {
                name: "免费更新赛事模板",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "新模板优先体验",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "同时创建活动数",
                version1: "无上限",
                version2: "无上限",
                version3: "无上限"
            },
            {
                name: "同时发布活动数",
                version1: "上限10",
                version2: "上限20",
                version3: "上限30"
            }, {
                name: "活动发布总次数",
                version1: "无上限",
                version2: "无上限",
                version3: "无上限"
            },
            {
                name: "城市定向挑战赛",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/no.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
			{
			    name: "私有化部署小程序",
			    version1: `<img src="${require('../assets/package/no.png')}" />`,
			    version2: `<img src="${require('../assets/package/no.png')}" />`,
			    version3: `<img src="${require('../assets/package/ok.png')}" />`
			}
        ]
    },
    {
        title: "报名系统特权",
        list: [{
                name: "自定义报名表单",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "报名收款",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "报名表单导入/导出",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "单个赛事报名人数",
                version1: "无上限",
                version2: "无上限",
                version3: "无上限"
            },
            {
                name: "报名H5接入自有公众号",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "报名H5接入自有小程序",
                version1: "可额外定制",
                version2: "可额外定制",
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }
        ]
    }, {
        title: "营销工具特权",
        list: [{
                name: "分享活动设置（海报、链接、二维码）",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "赛事push推送",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: "12次（限1次/月）",
                version3: "24次（限2次/月）"
            },
            {
                name: "抽奖（大转盘、摇一摇、刮刮乐等）",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "裂变红包",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "裂变电子优惠券",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "活动奖品核销",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }
        ]
    },
    {
        title: "运动银行特权",
        list: [{
                name: "邀请特权",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "商城返佣比例",
                version1: "30%",
                version2: "60%",
                version3: "90%"
            },
            {
                name: "运动银行H5接入自有公众号",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "运动银行H5接入自有小程序",
                version1: "可额外定制",
                version2: "可额外定制",
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "私域平台分佣比例",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/no.png')}" />`,
                version3: "90%"
            }

        ]
    },
    {
        title: "团队品牌特权",
        list: [{
                name: "团队人数",
                version1: "5人",
                version2: "10人",
                version3: "15人"
            },
            {
                name: "实名认证服务",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "专属标识",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: "银牌主办方",
                version3: "金牌主办方"
            },
            {
                name: "专属主页",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }

        ]
    }, {
        title: "数据看板特权",
        list: [{
                name: "浏览数据详情",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "下载数据详情（excel）",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "浏览可视化数据分析报告",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
            {
                name: "下载可视化数据分析报告(png、pdf)",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }, {
                name: "数据驾驶舱",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/no.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }, {
                name: "可视化大屏效果",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/no.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }

        ]
    }, {
        title: "活动推广特权",
        list: [{
                name: "活动置顶",
                version1: "12次（限1次/月）",
                version2: "24次（限2次/月）",
                version3: "36次（限3次/月）"
            },
            {
                name: "活动刷新",
                version1: "1次/天",
                version2: "2次/天",
                version3: "3次/天"
            },
            {
                name: "短信群发赠送服务",
                version1: "1000条",
                version2: "2000条",
                version3: "5000条"
            },
        ]
    }, {
        title: "优先服务特权",
        list: [{
                name: "活动审核速度",
                version1: "工作时间当天审核完毕",
                version2: "工作时间当天审核完毕",
                version3: "工作时间 4 小时内审核完毕"
            },
            {
                name: "票款提领速度",
                version1: "每月1日～15日申请<br />当月25日打款<br />每月16～30日申请<br />次月10日打款",
                version2: "申请后7个工作日",
                version3: "申请后5个工作日"
            },
            {
                name: "提款手续费",
                version1: "无减免",
                version2: "无减免",
                version3: "减免50%"
            }, {
                name: "赛事方案私人定制",
                version1: "1次/年",
                version2: "2次/年",
                version3: "3次/年"
            }, {
                name: "客服绿色通道",
                version1: `<img src="${require('../assets/package/ok.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            }, {
                name: "专属客户经理",
                version1: `<img src="${require('../assets/package/no.png')}" />`,
                version2: `<img src="${require('../assets/package/ok.png')}" />`,
                version3: `<img src="${require('../assets/package/ok.png')}" />`
            },
        ]
    }

]
let core = [{
    id: 1,
    title: '赛事模板',
    imgUrl: require("../assets/home/product1.png"),
    leftList: [{
            title: "种类丰富",
            titleSon: "囊括各种运动类型，让您轻松自定义赛事。",
            describe: "马拉松、AI运动、骑行、跑步、健步走、城市定向赛……各式各样的运动赛事，无需费时费力策划方案，一键创建即可拥有多场流程完善、规则清晰、参与度高的线上赛事。",
        },
        {
            title: "玩法灵活",
            titleSon: "超多超有趣玩法组合，让赛事有颜更有料！",
            describe: "团队PK、排位赛、地图解锁、运动打卡，AI趣运动等等，这里有火爆全网的赛事玩法、全民参与的运动形式，想怎么玩就怎么玩！",
        },
        {
            title: "花式奖励",
            titleSon: "突破传统奖品放发形式，选手更有参与感。",
            describe: "虚拟+实物奖品相结合，数字徽章、荣誉证书、现金红包、完赛包等，任意参赛选手均可通过努力完成运动任务来获取奖品，将每个人的参与感、荣誉感、收获感直接拉满！",
        },
    ],
}, {
    id: 2,
    title: '营销工具',
    imgUrl: require("../assets/home/product2.png"),
    leftList: [{
            title: "裂变海报",
            titleSon: "赛事分享、海报裂变，多渠道推广获客。",
            describe: "一键生成赛事海报或链接，通过第三方平台进行宣发，快速触达用户层，为赛事吸引更多的关注度。",
        },
        {
            title: "优惠券",
            titleSon: "依靠优惠、促销手段，制造热烈的赛事氛围。",
            describe: "奖品兑换券、电子优惠券等多种促销工具，为赛事营造紧张刺激的氛围感，促使更多用户参与其中。",
        },
        {
            title: "抽奖工具",
            titleSon: "超多超好玩的抽奖工具，让奖品领取更具新鲜感。",
            describe: "大转盘、摇一摇、刮刮乐等多种抽奖形式，趣味性十足，让用户在奖品领取时更有体验感。",
        },
    ],
}, {
    id: 3,
    title: '运动银行',
    imgUrl: require("../assets/home/product3.png"),
    leftList: [{
            title: "返利商城",
            titleSon: "自营云商城，坐拿佣金，体育、商业两不误！",
            describe: "提供全网热卖好货供应链，物美价廉有保障，轻松赚佣金！让您在推动体育赛事的同时，拥有额外收获",
        },
        {
            title: "打通私域",
            titleSon: "接入公众号、小程序，打通私域资源！",
            describe: "支持运动银行接入赛事运营商的微信公众号、小程序，激活私域平台的用户，实现流量变现。",
        }
    ],
}, {
    id: 4,
    title: '报名管理',
    imgUrl: require("../assets/home/product4.png"),
    leftList: [{
            title: "报名表",
            titleSon: "报名信息自定义，满足个性化赛事需求。",
            describe: "支持自定义赛事报名表单，所有报名信息都可导入导出，实现信息数字化管理，数据可追溯，管理更省心！",
        },
        {
            title: "H5",
            titleSon: "灵活的赛事呈现形式，满足多场景需求。",
            describe: "任意赛事创建后均可生成独立的H5链接，可接入小程序、公众号等多场景，实现快速私域导流。",
        },
    ],
}, {
    id: 5,
    title: '品牌主页',
    imgUrl: require("../assets/home/product5.png"),
    leftList: [{
        title: "站内号",
        titleSon: "认证品牌标识，提升权威感信任度！",
        describe: "赛事主办方拥有独立的、含品牌认证标识的站内账号，可通过管理主页，实现多赛事同步报名、消息接收等操作。",
    }],
}]
let contentList = [{
        title: "校园体育云教学解决方案",
        caption1: "体育教学面临的挑战",
        caption2: "体和友-启动体育云教学",
        rightImg: require("../assets/solution/content1.png"),
        module1: [{
                icon: require("../assets/solution/icon (1).png"),
                title: "自由度受限",
                content: "受场地空间、器材数量的限制，学生可以选择的运动类型、时间相对匮乏。",
            },
            {
                icon: require("../assets/solution/icon (2).png"),
                title: "体育作业难布置",
                content: "学生的课外运动量难以监测，老师难以开展丰富多彩的体育教学内容。",
            },
            {
                icon: require("../assets/solution/icon (3).png"),
                title: "体育赛事受阻",
                content: "受疫情影响，校运动会等校园赛事无法如期举办，师生互动性、体育精神难践行。",
            },
        ],
        module2: [{
                content: "让老师轻松布置体育作业，可视化数据了解学生运动情况",
            },
            {
                content: "云比赛、广播操、云体测随时办，打造线上化校园体育生态。",
            },
        ],
        exampleTitle: "第九套广播体操线上赛",
        exampleListTitle: [
            "赛事目的",
            "赛事主题",
            "赛事项目",
            "赛事玩法",
            "参赛对象",
            "赛事规则",
            "赛事亮点",
        ],
        exampleList: [{
                title: "赛事目的",
                content: "落实“中小学健康促进行动”，增强学生的体质和毅力，培养学生集体主义精神和展示自我面貌，丰富课余生活，智育和体育协调发展",
            },
            {
                title: "赛事主题",
                content: "展现青春光彩，运动伴我同行",
            },
            {
                title: "赛事项目",
                content: "广播体操",
            },
            {
                title: "赛事玩法",
                content: "AI运动评分",
            },
            {
                title: "参赛对象",
                content: "全体学生",
            },
            {
                title: "赛事规则",
                content: "学生以班级为单位参与比赛，系统将对学生的动作完成度进行打分，每名学生的最佳成绩将累积计入班级成绩，成绩最好的班级将获得奖励。",
            },
            {
                title: "赛事亮点",
                content: "比赛采用AI智能评分系统，通过一次次的线上训练提高学生的肢体动作精准度。一套整齐划一的广播体操，总能让人感受到它独有的魅力。",
            },
        ],
    },
    {
        title: "创新式企业文化建设解决方案",
        caption1: "企业组织发展新需要",
        caption2: "体和友-助力企业组织管理",
        rightImg: require("../assets/solution/content2.png"),
        module1: [{
                icon: require("../assets/solution/icon (4).png"),
                title: "文化建设需要",
                content: "90/00后进驻职场，新一代员工对企业文化、团队氛围提出更多元的需求。",
            },
            {
                icon: require("../assets/solution/icon (5).png"),
                title: "团队管理需要",
                content: "越来越多的公司提倡扁平化管理，调动每位员工的积极性，共创共建共赢。",
            },
            {
                icon: require("../assets/solution/icon (6).png"),
                title: "竞争力需要",
                content: "硬实力之外，员工对组织发展的创新性、团队活力、社会参与性提出了更高的要求。",
            },
        ],
        module2: [{
                content: "建设企业的同时，给员工更多的选择自由、时间自由。",
            },
            {
                content: "凝聚力、团队协作、组织氛围……云上见，安全、省心，效果佳。",
            },
        ],
        exampleTitle: "企业线上趣味运动会",
        exampleListTitle: [
            "赛事目的",
            "赛事主题",
            "赛事项目",
            "赛事玩法",
            "参赛对象",
            "赛事规则",
            "赛事亮点",
        ],
        exampleList: [{
                title: "赛事目的",
                content: "加强企业精神文明建设和文化建设，塑造企业特色运动文化，将企业红色党建文化、企业发展大事件融入全员的运动生活，有效提升企业文化软实力。",
            },
            {
                title: "赛事主题",
                content: "喜迎二十大，用运动照耀青春",
            },
            {
                title: "赛事项目",
                content: "AI运动（开合跳、仰卧起坐、平板支撑、俯卧撑）",
            },
            {
                title: "赛事玩法",
                content: "集合拼图",
            },
            {
                title: "参赛对象",
                content: "全体员工",
            },
            {
                title: "赛事规则",
                content: "赛事形式为每日运动打卡解锁“重温长征路”红色主题拼图，员工可在活动期间内自由选择运动项目进行运动打卡，完成后即可获得一张碎片，一周时间内累计获得5张碎片即可获得完整拼图，完成的职工可获得企业周边礼品。",
            },
            {
                title: "赛事亮点",
                content: "通过丰富多彩、内容有趣、参与性强的运动方式，向员工传播企业理念和党建文化，增强员工对工作的使命感、对企业的荣誉感、对团队的归属感。",
            },
        ],
    },
    {
        title: "数字化赛事活动解决方案",
        caption1: "当下赛事活动的运营难点",
        caption2: "体和友-让体育赛事轻松办、回报高",
        rightImg: require("../assets/solution/content3.png"),
        module1: [{
                icon: require("../assets/solution/icon (7).png"),
                title: "赛事成本高",
                content: "线下赛人工、物料、时间等投入成本高，且受疫情影响难以稳定开赛。",
            },
            {
                icon: require("../assets/solution/icon (8).png"),
                title: "国家政策需要",
                content: "数字化赛事登上舞台，概念新颖、可借鉴案例少，如何把握切入点是个难题。",
            },
            {
                icon: require("../assets/solution/icon (9).png"),
                title: "赛事管理",
                content: "从赛事策划、人员组织到落地报名等环节，链路长、数据易缺失，整体管理难度大。",
            },
            {
                icon: require("../assets/solution/icon (10).png"),
                title: "营销转化",
                content: "缺乏可裂变的营销工具，赛事推广效果、参与人数等投入回报难把控。",
            },
        ],
        module2: [{
                content: "海量赛事模板满足全场景的赛事需求。",
            },
            {
                content: "奖品设置、裂变工具、营销玩法助力赛事办的容易。",
            },
        ],
        exampleTitle: "自行车骑行竞速赛",
        exampleListTitle: [
            "赛事目的",
            "赛事主题",
            "赛事项目",
            "赛事玩法",
            "参赛对象",
            "赛事规则",
            "赛事亮点"
        ],
        exampleList: [{
                title: "赛事目的",
                content: "为迎接杭州亚运会的召开，营造全民迎亚运的良好氛围，同时更好地诠释杭州文化底蕴，展现杭州独有韵味，开展自行车骑行赛，带动杭州运动休闲文化旅游等产业发展。",
            },
            {
                title: "赛事主题",
                content: "助力亚运，骑迹西湖",
            },
            {
                title: "赛事项目",
                content: "自行车骑行",
            },
            {
                title: "赛事玩法",
                content: "解锁地图",
            },
            {
                title: "参赛对象",
                content: "自行车协会成员；自行车骑行爱好者",
            },
            {
                title: "赛事规则",
                content: "报名成员可在赛事规定时间内，据己自由时间按照规划路线完成每个景点的打卡。景点从曲院风荷出发，沿北山路途经断桥、宝石山、岳庙、苏堤，最后来到南屏晚钟。途经每个地点都会自动解锁地图上的景点，景点全部解锁完成地图自动点亮，完成骑行者可获得特制的电子纪念奖章。",
            }, {
                title: "赛事亮点",
                content: "通过丰富多彩、内容有趣、参与性强的运动方式，向员工传播企业理念和党建文化，增强员工对工作的使命感、对企业的荣誉感、对团队的归属感。",
            },
        ],
    },
    {
        title: "综合性体育赛事解决方案",
        caption1: "落实全民健身面临的挑战",
        caption2: "体和友-助力数字化体育赛事落地",
        rightImg: require("../assets/solution/content4.png"),
        module1: [{
                icon: require("../assets/solution/icon (11).png"),
                title: "后疫情时代",
                content: "疫情反复，致使各种赛事规划被停滞，体育模块的相关政策难落实。",
            },
            {
                icon: require("../assets/solution/icon (12).png"),
                title: "数字化时代",
                content: "数字化、智能化时代的到来，体育赛道如何突破现状，实现数字化转型。",
            },
            {
                icon: require("../assets/solution/icon (13).png"),
                title: "民生需求",
                content: "群众对体育的热情日益高涨，如何举办适宜全民参与的赛事活动的需求愈加迫切。",
            },
        ],
        module2: [{
                content: "赛事创建、发布、报名、奖品发放等，流程化、智能化实现。",
            },
            {
                content: "以全新体育赛事形式和创意，携手民众共创美好的体育文化生活。",
            },
        ],
        exampleTitle: "全民健身线上马拉松",
        exampleListTitle: [
            "赛事目的",
            "赛事主题",
            "赛事项目",
            "参赛对象",
            "赛事规则",
            "赛事亮点",
        ],
        exampleList: [{
                title: "赛事目的",
                content: "落实《全民健身计划（2021—2025年）》政策，丰富民众体育文化生活，领略城市美景，展现新时代人民的精神面貌。",
            },
            {
                title: "赛事主题",
                content: "我运动，我健康，我快乐",
            },
            {
                title: "赛事项目",
                content: "线上马拉松",
            },
            {
                title: "参赛对象",
                content: "所有运动爱好者",
            },
            {
                title: "赛事规则",
                content: "赛事组别分为1公里/3公里/5公里/10公里/半马，参赛者可自由选择组别和赛事套餐（可付费购买完赛证书和运动T恤）。在规定时间内上传运动数据，累计里程达到目标里程数即认为完赛。",
            },
            {
                title: "赛事亮点",
                content: "从赛前的报名、付费，到赛中自由选择组别、时间，到赛后公布成绩、发放奖品，一站式完成赛事运营。",
            },
        ],
    },
]
export default {
    jobs,
    packages,
    core,
    contentList
}