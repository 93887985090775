import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'



Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/solution',
        name: 'solution',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/solution.vue')
        }
    },
    {
        path: '/about',
        name: 'about',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/about.vue')
        }
    },
    {
        path: '/join',
        name: 'join',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/join.vue')
        }
    },
    {
        path: '/news',
        name: 'news',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/news.vue')
        }
    }, {
        path: '/joinDetail',
        name: 'joinDetail',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/joinDetail.vue')
        },
    }, {
        path: '/package',
        name: 'package',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/package.vue')
        },
    },

]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router