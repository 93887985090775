import { render, staticRenderFns } from "./swiper-core.vue?vue&type=template&id=9faf0446&scoped=true&"
import script from "./swiper-core.vue?vue&type=script&lang=js&"
export * from "./swiper-core.vue?vue&type=script&lang=js&"
import style0 from "./swiper-core.vue?vue&type=style&index=0&id=9faf0446&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9faf0446",
  null
  
)

export default component.exports