<template>
  <div
    class="nav"
    :style="changeScroll && changeIndex == 0 ? 'color:#fff;' : 'color:#333;'"
  >
    <div class="leftIcon">
      <div class="logo" @click="goHome">
        <img src="@/assets/home/logo.png" />
      </div>
      <div class="content">
        <div
          class="item"
          @click="goHome"
          :class="changeCount == 0 ? 'active' : ''"
        >
          首页
        </div>
        <div
          class="item"
          @mouseleave="mouseleave($event, 'show1')"
          @mouseenter="mouseenter($event, 'show1')"
          :class="changeCount == 1 ? 'active' : ''"
        >
          产品与服务
          <i class="el-icon-arrow-down"></i>
          <transition name="el-zoom-in-top">
            <div v-show="show1" class="transition-box transition-box1">
              <div class="box-content">
                <div
                  class="arrow"
                  :style="
                    changeScroll && changeIndex == 0
                      ? ' border-color: transparent transparent #fff transparent;'
                      : ''
                  "
                ></div>
                <ul>
                  <li class="first">五大产品</li>
                  <li @click="product(0)">赛事模板</li>
                  <li @click="product(1)">营销工具</li>
                  <li @click="product(2)">运动银行</li>
                  <li @click="product(3)">报名管理</li>
                  <li @click="product(4)">品牌主页</li>
                </ul>
                <ul>
                  <li class="first">五大服务</li>
                  <li @click="service(0)">用户管理</li>
                  <li @click="service(1)">交易管理</li>
                  <li @click="service(2)">专属管家</li>
                  <li @click="service(3)">数据看板</li>
                  <li @click="service(4)">流量特权</li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
        <div
          class="item"
          :class="changeCount == 2 ? 'active' : ''"
          @click="toPackage"
        >
          价格套餐
        </div>
        <div
          class="item"
          @mouseleave="mouseleave($event, 'show2')"
          @mouseenter="mouseenter($event, 'show2')"
          :class="changeCount == 3 ? 'active' : ''"
        >
          解决方案
          <i class="el-icon-arrow-down"></i>
          <transition name="el-zoom-in-top">
            <div v-show="show2" class="transition-box transition-box2">
              <div class="box-content">
                <div
                  class="arrow"
                  :style="
                    changeScroll && changeIndex == 0
                      ? ' border-color: transparent transparent #fff transparent;'
                      : ''
                  "
                ></div>
                <ul class="other">
                  <li @click="solution(0)">学校</li>
                  <li @click="solution(1)">企业</li>
                  <li @click="solution(2)">协会</li>
                  <li @click="solution(3)">政府</li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
        <div
          class="item"
          @mouseleave="mouseleave($event, 'show4')"
          @mouseenter="mouseenter($event, 'show4')"
          :class="changeCount == 5 ? 'active' : ''"
        >
          关于我们
          <i class="el-icon-arrow-down"></i>
          <transition name="el-zoom-in-top">
            <div v-show="show4" class="transition-box transition-box4">
              <div class="box-content">
                <div
                  class="arrow"
                  :style="
                    changeScroll && changeIndex == 0
                      ? ' border-color: transparent transparent #fff transparent;'
                      : ''
                  "
                ></div>
                <ul class="other">
                  <li @click="toAbout(1)">公司简介</li>
                  <li @click="toAbout(2)">业务生态</li>
                  <li @click="toAbout(3)">新闻资讯</li>
                  <li @click="toAbout(4)">加入我们</li>
                  <li @click="toAbout(5)">联系我们</li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="login">
        <div class="login-left" @click="toExternal">工作台</div>
        |
        <div class="login-right" @click="toExternal">登录</div>
      </div>
      <div class="work" @click="toExternal">免费试用</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  props: {
    status: 1,
    src: "",
    index: 0,
    isScroll: false,
    count: 0,
  },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    };
  },
  mounted() {},
  computed: {
    changeIndex: function () {
      return this.index;
    },
    changeScroll: function () {
      return this.isScroll;
    },
    changeCount: {
      get() {
        return this.count;
      },
      set(val) {},
    },
  },
  methods: {
    handleSelect() {},
    mouseleave(e, type) {
      e.target.childNodes[1].style.transform = " rotateZ(0deg)";
      this[type] = false;
    },
    mouseenter(e, type) {
      e.target.childNodes[1].style.transform = " rotateZ(180deg)";
      this[type] = true;
    },
    solution(id) {
      this.$router.push({ path: "/solution", query: { id } });
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    toExternal(){
      console.log(location.origin);
      window.open('https://www.tiheyou.com/customer')
    },
    toPackage() {
      this.$router.push({ path: "/package" });
    },
    toAbout(id) {
      if (id == 3 || id == 4) {
        let url = "/news";
        if (id == 4) {
          url = "/join";
        }
        this.$router.push({ path: url });
      } else {
        this.$emit("scrollDetail", id);
        this.$router.push({ path: "/about", query: { id } });
      }
    },
    product(e) {
      this.$emit('product',e)
      this.$router.push({ path: "/",query: {id:e,type:'product'}});
    },
    service(e) {
      this.$emit('service',e)
      this.$router.push({ path: "/",query:{id:e,type:'service'}});
    },
  },
};
</script>

<style scoped lang='less'>
.nav {
  font-size: 16px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .content {
    display: flex;
    height: 100%;
    margin-left: 50px;
    box-sizing: border-box;
    justify-content: space-around;
    .item {
      height: 100%;
      line-height: 90px;
      padding: 0 26px;
      position: relative;
      transition: all 0.5s;
      .el-icon-arrow-down {
        transition: all 0.5s;
      }
    }
    .active {
      color: #fd7a0d !important;
    }
    .transition-box {
      position: absolute;
      z-index: 200;
      margin-top: 0px;
      .box-content {
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 4px 4px;
        background-color: #fff;
        border-top: 1px solid #fd7a0d;
        padding-top: 10px;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: space-around;
        > ul {
          list-style: none;
          padding: 0;
          cursor: pointer;
          li {
            font-size: 16px;
            color: #999999;
            padding: 0 20px 0 0;
            line-height: 44px;
          }
          .first {
            color: #666;
            font-size: 14px;
            border-bottom: 1px solid #f1f1f1;
            &::before {
              content: " ";
              width: 3px;
              height: 12px;
              display: inline-block;
              margin-right: 10px;
              background-color: #fd7a0d;
            }
          }
        }
        .other {
          li {
            font-size: 16px;
            color: #333;
            padding: 0;
            font-weight: 500;
            line-height: 40px;
          }
        }
      }
    }
    .transition-box1 {
      width: 308px;
      height: 278px;
    }
    .transition-box2 {
      width: 104px;
      height: 174px;
    }
    .transition-box4 {
      width: 136px;
      height: 214px;
    }
    .arrow {
      position: absolute;
      top: -20px;
      width: 0px;
      height: 0px;
      left: 35px;
      border-width: 10px 6px;
      display: flex;
      justify-content: center;
      border-style: solid;
      border-color: transparent transparent #fd7a0d transparent;
    }
  }
  .leftIcon {
    flex: 1;
    height: 90px;
    display: flex;
    align-items: center;
    img {
      width: 100px;
      height: 30px;
    }
  }
  .right {
    cursor: pointer;
    width: 218 px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .work {
      height: 30px;
      padding: 2px 10px;
      border-radius: 4px;
      line-height: 30px;
      background: linear-gradient(132deg, #fd7a0d 0%, #ff9d4b 100%);
      box-shadow: 0px 0px 10px 1px rgba(107, 49, 0, 0.2);
      color: #fff;
    }
    .login {
      display: flex;
      align-items: center;
      div {
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 60px;
      }
    }
  }
}
</style>
