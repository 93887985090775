<template>
  <div class="event">
    <div class="content">
      <h2>线上赛事,&nbsp;&nbsp;体育赛事的新蓝海</h2>
      <div class="box">
        <swiperCoverflow ref="swiperCoverflow"></swiperCoverflow>
        <div class="change">
          <div
            class="left"
            @click="clickLeft"
            @mousedown="mousedown"
            @mouseup="mouseup"
            @mouseleave="mouseup"
          >
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="line"></div>
          <div
            class="right"
            @click="clickRight"
            @mousedown="mousedown"
            @mouseup="mouseup"
            @mouseleave="mouseup"
          >
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swiperCoverflow from "@/components/swiperC-Coverflow.vue";

export default {
  name: "event",
  components: {
    swiperCoverflow,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    mouseleave() {
      e.target.style.background = 1;
    },
    mousedown(e) {
      e.target.style.opacity = 0.5;
    },
    mouseup(e) {
      e.target.style.opacity = 1;
    },
    clickRight(){
      this.$refs.swiperCoverflow.slideNext()

    },
    clickLeft(){
        this.$refs.swiperCoverflow.slidePrev()

    }
  },
};
</script>
<style lang="less" scoped>
.swiper-container {
  width: 500px;
  height: 300px;
  margin: 20px auto;
}
.event {
  width: 100%;
  height: 1000px;
  background-color: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .content {
    margin: 0 auto;
    background-image: url("../assets/home/swiperBg.png");
    background-size: 100% 100%;
    height: 100%;
    color: #000;
    overflow: hidden;

    h2 {
      margin: 110px 0;
      font-size: 40px;
    }
    .box {
      width: 1480px;
      height: 642px;
      overflow: hidden;
      margin: 0 auto;
    }
    .change {
      box-sizing: border-box;
      margin: 150px auto;
      width: 100px;
      height: 30px;
      border-radius: 30px;
      background-color: #fff;
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      cursor: pointer;
      .left,
      .right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-icon-arrow-right,.el-icon-arrow-left{
            font-weight: 600;
        }
      }
      .line {
        height: 10px;
        width: 1px;
        border-left: 1px solid #cccccc;
      }
    }
  }
}
</style>