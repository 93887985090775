import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

import VueAMap from "vue-amap";

Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
    key: "83aa58819900c63cb5581d65ebfb6fcf",
    plugin: [
        "AMap.Autocomplete",
        "AMap.PlaceSearch",
        "AMap.Scale",
        "AMap.OverView",
        "AMap.ToolBar",
        "AMap.MapType",
        "AMap.PolyEditor",
        "AMap.CircleEditor",
        "AMap.Geocoder"
    ],
    // 默认高德 sdk 版本为 1.4.4
    v: "1.4.4"
});
Vue.use(ElementUI);
new Vue({
    router,
    render: function(h) { return h(App) }
}).$mount('#app')