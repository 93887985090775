<template>
  <div class="block">
    <el-carousel @change="change" :autoplay='false' trigger="click" :height="height+'px'">
      <div class="dot"></div>
      <el-carousel-item v-for="item in swiperList" :key="item.id">
        <div class="box">
          <img
            :src="item.url"
            alt=""
            :style="{height:height+'px'}"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
          />
          <div class="first" v-if="item.id==1">
            <h2>运动 , 多一点</h2>
            <div class="button">
              <button @mousedown="mousedown" @mouseup="mouseup" @mouseleave="mouseup">ios下载</button>
              <button @mousedown="mousedown" @mouseup="mouseup" @mouseleave="mouseup">安卓下载</button>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "swiper",
  props: {},
  data() {
    return {
      isSwitch: true,
      height:990,
      swiperList: [
        {
          id: 1,
          url: require("../assets/home/banner1.png"),
        },
        {
          id: 2,
          url: require("../assets/home/banner2.png"),
        }
      ],
    };
  },
     mounted(){
    window.addEventListener('resize',()=>{
       this.height = window.innerHeight
    })
     },
  methods: {
    change(e) {
      if (this.isSwitch) this.$emit("change", e);
    },
    mouseenter() {
      this.isSwitch = false;
    },
    mouseleave() {
      this.isSwitch = true;
    },
    mousedown(e){
      e.target.style.opacity = .5
    },
    mouseup(e){
     e.target.style.opacity = 1
    }
  },
};
</script>
<style lang="less" scoped>
.block {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img {
    width: 100%;
    display: block;
  }
  .box{
    position: relative;
  }
  .first {
    position: absolute;
    z-index: 190;
    top: 44%;
    left: 20%;
    h2{
      font-size: 80px;
    }
    color: #fff;
    .button{
      width: 400px;
      margin-left: 0px;
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      button{
        width: 166px;
        height: 48px;
        font-size: 20px;
        border-radius: 48px;
        background-color: #FD7A0D;
        border: 0;
        outline: none;
        color: #fff;
      }
    }
  }
  .dot {
    width: 24px;
    height: 32px;
    border-radius: 14px 14px 14px 14px;
    opacity: 1;
    border: 2px solid #ffffff;
    position: absolute;
    left: calc(50% - 36px);
    z-index: 180;
    transition: all 0.5s linear;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    animation: fluctuate linear 1.5s infinite;
    &::after {
      display: block;
      content: " ";
      width: 4px;
      margin-bottom: 3px;
      height: 10px;
      border-radius: 4px;
      background-color: #fff;
    }
  }
  /deep/.el-carousel__indicators {
    left: 24%;
    bottom: 4%;
  }
  /deep/.el-carousel__indicator {
    .el-carousel__button {
      width: 20px;
      height: 5px !important;
      border-radius: 5px;
    }
  }
  /deep/ .is-active {
    .el-carousel__button {
      width: 50px;
    }
  }
}
@keyframes fluctuate {
  0% {
    bottom: 30px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 30px;
  }
}
</style>