<template>
  <div class="sider">
    <div
      class="wx"
      @mouseleave="mouseleave($event, 'show1')"
      @mouseenter="mouseenter($event, 'show1')"
    >
      <img src="../assets/home/wx.png" alt="" />
      <div>微信</div>
      <transition name="el-fade-in-linear">
        <div v-show="show1" class="transition-box">
          <div class="box-content">
           <img src="../assets/home/code.png" alt="">
           <p>官方微信</p>
          </div>
        </div>
      </transition>
    </div>
    <div class="phone"
    @mouseleave="mouseleave($event, 'show2')"
      @mouseenter="mouseenter($event, 'show2')"
    >
      <img src="../assets/home/phone.png" alt="" />
      <div>电话</div>
      <transition name="el-fade-in-linear">
        <div v-show="show2" class="transition-box transition-box2">
          <div class="box-content2">
           电话咨询:400-062-2989
          </div>
        </div>
      </transition>
    </div>
    <div class="backTop" @click="$emit('clickTop')">
      <img src="../assets/home/backTop.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "sider",
  data() {
    return {
      show1: false,
      show2:false
    };
  },
  methods: {
    mouseleave(e, type) {
      this[type] = false;
    },
    mouseenter(e, type) {
      this[type] = true;
    },
  },
};
</script>
<style lang="less" scoped>
.sider {
  width: 50px;
  height: 216px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(143, 65, 0, 0.2);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  position: fixed;
  right: 30px;
  top: 50%;
  z-index: 190;
  padding: 10px 0;
  cursor: pointer;
  > div {
    display: flex;
    flex-flow: column;
    width: 70%;
    margin: 0 auto;
    height: 80px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: relative;
    > img {
      width: 28px;
      height: 24px;
    }
  }
  .phone {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .backTop{
    height: 60px;
  }
  .transition-box {
    position: absolute;
    width: 100px;
    height: 120px;
    left: -115px;
    border-radius: 10px;
    background-color: #fff;
    padding: 2px;
    padding-bottom: 4px;
    img{
      width: 95px;
      height: 95px;
      border-radius: 10px;
    }
  }
  .transition-box2{
    width: 168px;
     height: 37px;
    left: -182px;
    line-height: 40px;
  }
}
</style>