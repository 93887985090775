<template>
  <div class="service">
    <div class="modified1"></div>
    <div class="modified2"></div>
    <p>五大核心服务</p>
    <div class="tabs">
      <div
        class="tab-item"
        v-for="(item, index) in tabs"
        :key="index"
        :class="index == count ? 'tab-active' : ''"
        @click="clickTab(index)"
      >
        <img :src="index == count ? item.icon2 : item.icon1" />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="serviceSwiper">
      <el-carousel
        indicator-position="outside"
        arrow="never"
        height="400px"
        class="elCarousel"
      >
        <el-carousel-item
          v-for="(item, index) in tabs[count].swiperList"
          :key="index"
          class="list"
        >
          <transition name="el-fade-in" class="transition">
            <div class="content" v-show="show">
              <div class="left">
                <img :src="item.imgList" alt="" />
              </div>
              <div class="right">
                <p class="title">{{ item.title }}</p>
                <p class="describe">{{ item.describe }}</p>
                <p>{{ item.content }}</p>
                <span
                  class="more"
                  @mousedown="mousedown"
                  @mouseup="mouseup"
                  @mouseleave="mouseup"
                  >了解详情<i class="el-icon-arrow-right"></i
                ></span>
              </div>
            </div>
          </transition>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "service",
  data() {
    return {
      count: 0,
      show: true,
      tabs: [
        {
          name: "用户管理",
          icon1: require("../assets/home/serviceIcon1-1.png"),
          icon2: require("../assets/home/serviceIcon1-0.png"),
          swiperList: [
            {
              imgList: require("../assets/home/macbook-mock-1.png"),
              title: "管理列表",
              describe: "建立用户池，打造用户管理空间。",
              content:
                "记录用户信息，可快速检索用户并进行用户分析；刻画用户来源数据，便于分析用户路径，优化推广策略。",
            },
            {
              imgList: require("../assets/home/macbook-mock-2.png"),
              title: "用户分析",
              describe: "标签分类、特性分析，用数据做好用户维护！",
              content:
                "依据用户行为进行标签分类，便于精准推荐赛事活动；并对用户年龄、地区等特性进行数据分析，提升用户运营效率。",
            },
          ],
        },
        {
          name: "交易管理",
          icon1: require("../assets/home/serviceIcon2-0.png"),
          icon2: require("../assets/home/serviceIcon2-1.png"),
          swiperList: [
            {
              imgList: require("../assets/home/macbook-mock-3.png"),
              title: "赛事订单",
              describe: "全链路赛事订单信息，笔笔可查证。",
              content:
                "报名订单状态、参赛情况、完赛信息等支持多维度筛选、查看，实时监测赛前、赛中、赛后的详细情况。",
            },
            {
              imgList: require("../assets/home/macbook-mock-4.png"),
              title: "商城订单",
              describe: "订单筛选与物流跟踪，牢牢把握每个环节！",
              content:
                "可查看商品信息、订单状态、物流等记录，及时掌握每笔订单的“生命历程”，便于商城售后管理工作。",
            },
          ],
        },
        {
          name: "专属管家",
          icon1: require("../assets/home/serviceIcon3-0.png"),
          icon2: require("../assets/home/serviceIcon3-1.png"),
          swiperList: [
            {
              imgList: require("../assets/home/macbook-mock-5.png"),
              title: "服务管家",
              describe: "1v1专属服务管家，贴心护航。",
              content:
                "专属管家7*24小时在线，随时为您提供答疑解惑，全方位的陪伴服务，为您的业务保驾护航。",
            },
            {
              imgList: require("../assets/home/macbook-mock-6.png"),
              title: "私人定制",
              describe: "专业团队，为您定制个性化需求。",
              content:
                "赛事模板、玩法等支持私人订制，提出想法即有金牌服务团队快速响应，您的需求，我们的追求！",
            },
          ],
        },
        {
          name: "数据看板",
          icon1: require("../assets/home/serviceIcon4-0.png"),
          icon2: require("../assets/home/serviceIcon4-1.png"),
          swiperList: [
            {
              imgList: require("../assets/home/macbook-mock-7.png"),
              title: "数据可视化",
              describe: "多维度数据看板，及时监控、调整。",
              content:
                "赛事、商城、用户等数据支持生成智能化、可视化的数据报表，通过多维分析及时掌握全盘业务。",
            },
            {
              imgList: require("../assets/home/macbook-mock-8.png"),
              title: "报表下载",
              describe: "各项数据报表，支持一键下载！",
              content:
                "赛事报名、订单等报表，数据详尽、实时更新，支持随时下载，无次数限制，随时随地灵活进行数据分析，为业务优化提供数据支持。",
            },
          ],
        },
        {
          name: "流量特权",
          icon1: require("../assets/home/serviceIcon5-0.png"),
          icon2: require("../assets/home/serviceIcon5-1.png"),
          swiperList: [
            {
              imgList: require("../assets/home/macbook-mock-9.png"),
              title: "",
              describe: "线上推广小功能，触达更多流量。",
              content:
                "依实际需求精选赛事活动，巧用置顶、刷新、短信群发等功能，让关键活动获取更多曝光机会。",
            },
          ],
        },
      ],
    };
  },
 
  methods: {
    clickTab(index) {
      this.count = index;
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 100);
    },
    mouseenter() {
      this.isSwitch = false;
    },
    mouseleave() {
      this.isSwitch = true;
    },
    mousedown(e) {
      e.target.style.opacity = 0.5;
    },
    mouseup(e) {
      e.target.style.opacity = 1;
    },
    toIndex(e) {
     this.count = e
    }
  },
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 1170px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > p {
    font-size: 40px;
    font-weight: 600;
    margin: 150px 0 107px;
    color: #000;
  }
  .modified1 {
    width: 177px;
    height: 144px;
    top: 225px;
    left: 98px;
    position: absolute;
    background: url("../assets/home/serviceBg1.png") no-repeat;
    background-size: 100% 100%;
  }
  .modified2 {
    width: 535px;
    height: 278px;
    top: 739px;
    right: -225px;
    position: absolute;
    background: url("../assets/home/serviceBg2.png") no-repeat;
    background-size: 100% 100%;
  }
  .tabs {
    width: 1040px;
    height: 58px;
    margin: 0 auto;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    .tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      width: 180px;
      height: 58px;
      border-radius: 16px;
      color: #333333;
      background-color: #f7f7f7;
      transition: all 0.2s;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .tab-active {
      background-color: #fd7a0d;
      color: #fff;
    }
  }
  .serviceSwiper {
    margin: 100px auto 0;
    width: 1220px;
    height: 480px;
    .elCarousel {
      width: 100%;
      height: 100%;
      /deep/.el-carousel__button {
        width: 40px;
        height: 6px;
        border-radius: 5px;
      }
      /deep/.is-active {
        .el-carousel__button {
          background-color: #fd7a0d;
        }
      }
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        .left {
          width: 570px;
          img {
            width: 100%;
          }
        }
        .right {
          flex: 1;
          height: 307px;
          text-align: left;
          padding: 0px 76px 34px;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #333;
            margin: 76px 0 36px;
          }
          .describe {
            color: #333;
            font-size: 18px;
            margin-bottom: 16px;
          }
          p {
            font-size: 14px;
            color: #999;
          }
          span {
            color: #fd7a0d;
            font-size: 18px;
            border-radius: 50px;
            padding: 4px 10px 4px 12px;
            display: inline-block;
            margin-top: 20px;
            border: 1px solid #fd7a0d;
          }
        }
      }
    }
  }
}
</style>