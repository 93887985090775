<template>
  <div class="footer">
    <div class="content">
      <div class="footer-top">
        <div class="left">
          <div class="logo">
            <img src="../assets/home/logo2.png" alt="" />
          </div>
          <div class="code">
            <div class="codeLeft">
              <div>
                <img src="../assets/home/code1.png" alt="" />
                <span>扫码关注<br />官方微信公众号</span>
              </div>
              <div>
                <img src="../assets/home/code2.png" alt="" />
                <span>扫码关注<br />体和友微博</span>
              </div>
            </div>
            <div class="codeRight">
              <div @click="toBaijiahao">
                <img src="../assets/home/icon1.png" alt="" />
                <span>百家号</span>
              </div>
              <div @click="toToutiao">
                <img src="../assets/home/icon2.png" alt="" />
                <span>头条号</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <ul>
            <li class="first">产品中心</li>
            <li>体和友</li>
            <li>体和友SAAS</li>
          </ul>
          <ul>
            <li class="first">帮助中心</li>
            <li>产品操作手册</li>
            <li>成为赛事方</li>
            <li>快速提现</li>
            <li>快速开发票</li>
          </ul>
          <ul>
            <li class="first">解决方案</li>
            <li>学校</li>
            <li>企业</li>
            <li>协会</li>
            <li>政府</li>
          </ul>
          <ul>
            <li class="first">关于我们</li>
            <li>公司简介</li>
            <li>业务生态</li>
            <li>新闻资讯</li>
            <li>加入我们</li>
            <li>联系我们</li>
          </ul>
          <ul>
            <li class="first">法律与合规</li>
            <li>服务协议</li>
            <li>隐私政策</li>
            <li>安全与合规</li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="contact">
          <span>联系电话：400-062-2989 </span>
          <img
            src="../assets/home/icon3.png"
            alt=""
            @mouseleave="mouseleave($event, 'show')"
            @mouseenter="mouseenter($event, 'show')"
          />
          <transition name="el-fade-in-linear">
            <div v-show="show" class="transition-box">
              <div class="box-content">
                <img src="../assets/home/code.png" alt="" />
              </div>
            </div>
          </transition>
        </div>
        <div class="address">
          公司地址：浙江省杭州市滨江区江虹路浙农科创园1号楼1108
        </div>
        <div class="information">
          Copyright © 2022
          浙江中体文化集团有限公司&emsp;&emsp;ICP备案号：浙ICP备2020042866号
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footers",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toToutiao() {
      window.open(
        "https://www.toutiao.com/c/user/token/MS4wLjABAAAAwnfZPSreLsURWPZPsTgh8XAip1CAkzgbHhw7hMBEhtNGqF10CUbzd-KLobWx68cx/?"
      );
    },
    toBaijiahao() {
      window.open("https://baijiahao.baidu.com/u?app_id=1739291979175088");
    },
    mouseleave(e, type) {
      this[type] = false;
    },
    mouseenter(e, type) {
      this[type] = true;
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  min-width: 1320px;
  height: 500px;
  box-sizing: border-box;
  background-color: #211f1d;
  .content {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    color: #fff;
    .footer-top {
      display: flex;
      height: 325px;
          box-sizing: border-box;
    padding-top: 43px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ffffff8c;
      .left {
        width: 416px;
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-right: 1px solid #ffffff8c;
        .logo {
          width: 120px;
          height: 36px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .code {
          display: flex;
          height: 140px;
          font-size: 14px;
          .codeLeft {
            // border-right: 1px solid #ffffff2f;
            display: flex;
            width: 260px;
            text-align: center;
            line-height: 20px;
            > div {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 24px;
            }
            span {
              margin-top: 10px;
            }
            img {
              width: 100px;
              height: 100px;
            }
          }
          .codeRight {
            flex: 1;
            height: 100px;
            cursor: pointer;
            border-left: 1px solid #ffffff2f;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > div {
              display: flex;
              display: flex;
              margin-bottom: 20px;
              flex-direction: column;
              align-items: center;
              &:hover{
                span {
                opacity: 1;
              }
              }
              span {
                margin-top: 4px;
                opacity: 0.8;
              }
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .right {
        display: flex;
        height: 230px;
        flex: 1;
        padding-top: 10px;
        cursor: pointer;
        box-sizing: border-box;
        padding-left: 60px;
        justify-content: space-between;
        ul {
          list-style: none;
          text-align: left;
          line-height: 36px;
          font-weight: 400;
          color: #ffffff;
          .first {
            font-size: 18px;
            line-height: 0;
            margin: 10px 0 30px;
            color: #ffffff;
             color: #ffffff;
          opacity: 1;
          }
          li{
          color: #ffffff;
          opacity: .7;
          &:hover{
            opacity: 1;
          }
          }
        }
      }
    }
    .footer-bottom {
      font-size: 16px;
      height: 150px;
      margin-top: 20px;
      // opacity: 0.8;
      
      display: flex;
      flex-direction: column;
      text-align: left;
      .contact {
        margin-top: 20px;
        position: relative;
        img {
          width: 18px;
          height: 18px;
          vertical-align: bottom;
        }
        .transition-box {
          position: absolute;
          width: 100px;
          height: 100px;
              top: -70px;
            left: 270px;
          z-index: 300;
          border-radius: 10px;
          background-color: #fff;
          box-sizing: border-box;
          padding: 2px;
          img {
            width: 95px;
            height: 95px;
            border-radius: 10px;
          }
        }
      }
      .address {
        line-height: 40px;
      }
      .information {
        font-size: 12px;
        line-height: 50px;
        opacity: 0.8;
      }
    }
  }
}
</style>