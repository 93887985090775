<template>
  <div class="home">
    <div class="top" :style="scroll < 20 ? styles[index] : ''">
      <nav-bar
        class="navBar"
        :index="index"
        :isScroll="scroll == 0 ? true : false"
        @product="product"
        @service="service"
        :count="0"
      ></nav-bar>
    </div>
    <swiper @change="change"></swiper>
    <event />
    <core-product ref="coreProduct" />
    <service  ref="service"/>
    <cooperation />
    <router-view />
    <transition name="el-zoom-in-top">
      <sider v-show="scroll>500" @clickTop="clickTop"></sider>
    </transition>
    <footers />
  </div>
</template>

<script>
// @ is an alias to /src
import navBar from "@/components/navBar.vue";
import swiper from "@/components/swiper.vue";
import service from "@/components/service.vue";
import sider from "@/components/sider.vue";
import footers from "@/components/foot.vue";
import event from "@/components/event.vue";
import Cooperation from "@/components/cooperation.vue";
import coreProduct from "@/components/coreProduct.vue";
export default {
  name: "HomeView",
  components: {
    navBar,
    swiper,
    sider,
    footers,
    event,
    Cooperation,
    coreProduct,
    service
  },
  data() {
    return {
      index: 0,
      scroll: 0,
      styles: [
        { background: "rgba(0,0,0,.5)" },
        { background: "#fff" },
        { background: "#fff" },
      ],
    };
  },
  mounted() {
    let query = this.$route.query;
    document.querySelectorAll('title')[0].innerText = '体和友-赛事管理系统-体育赛事-马拉松-赛事报名-线上赛事'
    document.querySelectorAll('meta')[3].content = '体和友赛事管理系统,体育赛事,体育app,马拉松,赛事报名系统,线上赛事,运动赛事,运动app,健身app,户外俱乐部'
    document.querySelectorAll('meta')[4].content = '体和友，一款全景数字化的体育赛事生态SaaS系统，拥有先进的AI识别技术，自动计步算法，物联网等技术，围绕体育赛事活动为商户提供丰富的运动类型，赛事模板，营销工具等。运动，多一点，等你来解锁……'
    if(query&&query.type){
        if(query.type=='product'){
          this.product(query.id)
        }else{
          this.service(query.id)
        }
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scroll = scrollTop;
    },
    change(e) {
      this.index = e;
    },
    clickTop() {
      document.documentElement.scrollTop = 0;
    },
    product(e){
    document.documentElement.scrollTop=2040
      this.$refs.coreProduct.toIndex(e)
    },
    service(e){
      document.documentElement.scrollTop=3080
       this.$refs.service.toIndex(e)
    }
  },
};
</script>
<style lang="less">
  .top{
    box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.1);
  }
</style>
