<template>
  <div class="cooperation">
    <div class="partner">
      <p>合作伙伴</p>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img :src="item.logo" alt="" />
        </div>
      </div>
    </div>
    <div class="joinIn">
      <div class="box">
        <div class="left">
          <div class="title">
            <img src="../assets/home/hand.png" alt="" />
            <span>诚招代理加盟</span>
          </div>
          <p>赢行业先机 开赛事新局</p>
          <p>联系我们：400-062-2989</p>
        </div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "cooperation",
  data() {
    return {
      list: [
        {
          name: "国家体育局",
          logo: require("../assets/home/partner1.png"),
        },
        {
          name: "浙江省体育局",
          logo: require("../assets/home/partner2.png"),
        },
        {
          name: "杭州2022年亚运会",
          logo: require("../assets/home/partner3.png"),
        },
        {
          name: "浙江震元股份有限公司",
          logo: require("../assets/home/partner4.png"),
        },
        {
          name: "中国移动",
          logo: require("../assets/home/partner5.png"),
        },
        {
          name: "中国电信",
          logo: require("../assets/home/partner6.png"),
        },
        {
          name: "杭州银行",
          logo: require("../assets/home/partner7.png"),
        },
        {
          name: "华为",
          logo: require("../assets/home/partner8.png"),
        },
        {
          name: "橙狮体育（原阿里体育）",
          logo: require("../assets/home/partner9.png"),
        },
        {
          name: "海康威视",
          logo: require("../assets/home/partner10.png"),
        },
        {
          name: "喜临门",
          logo: require("../assets/home/partner11.png"),
        },
        {
          name: "众合科技",
          logo: require("../assets/home/partner12.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.cooperation {
    width: 100%;
  height: 1223px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  .partner {
    height: 723px;
    width: 100%;
    background: url("../assets/home/partnerBg.png");
    background-size: 100% 100%;
    background-color: #f7f7f7;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    >p{
      margin: 90px 0 60px;
      font-size: 40px;
      font-weight: bold;
      color: #000;
    }
    .list {
      width: 1180px;
      height: 372px;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      > .item {
        width: 170px;
        height: 170px;
        border-radius: 8px;
        transition: all 0.5s;
        &:hover {
          box-shadow: 0px 10px 10px 1px rgba(128, 58, 0, 0.1);
          transform: scale(1.1);
        }
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .joinIn {
    flex: 1;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      width: 1180px;
      height: 323px;
      position: relative;
      .left {
        width: 1015px;
        height: 295px;
        position: absolute;
        left: -30px;
        top: 30px;
        background: url("../assets/home/bottomLeft.png");
        background-size: 100% 100%;
        z-index: 101;
        p{
            font-size: 20px;
            text-align: left;
            padding: 0px 90px;
            margin-top: 16px;
            font-weight: 500;
        }
        .title{
            padding: 50px 60px 0 90px;
            display: flex;
            font-size: 40px;
            font-weight: bold;
           align-items: center;
           color: #000;
            img{
                width: 47px;
                height: 40px;
                margin-right: 10px;
            }
        }
      }
      .right {
        width: 740px;
        height: 323px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100;
        background: url("../assets/home/bottomRight.png");
        background-size: 100% 100%;
      }
    }
  }
}
</style>