<template>
  <div class="box2">
    <swiper
      class="swiper"
      ref="informationSwiper"
      :options="swiperOption"
      @slideTo="slideTo(0, 1000, false)"
      
    >
      <swiper-slide v-for="(itemf, indexf) in list" :key="indexf" class="con">
        <div class="topTitle">{{itemf.title}}</div>
        <div class="boxContent">
          <div class="left">
            <div
              class="leftList"
              v-for="(item, index) in itemf.leftList"
              :key="index"
              :class="count == index ? 'listActive' : ''"
              @mouseenter="mouseenter(index)"
            >
              <div class="title">{{ item.title }}</div>
              <div class="titleSon">{{ item.titleSon }}</div>
              <p>{{ item.describe }}</p>
            </div>
            <div class="bottom">
              <span class="more"
                >了解详情<i class="el-icon-arrow-right"></i
              ></span>
            </div>
          </div>
          <div class="right">
            <img :src="itemf.imgUrl" alt="" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
      <div class="swiper-pagination"></div>

  </div>
</template>

<script>
import myData from "@/common/data.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "swiper-core",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      opacity: 0,
      count: 0,
      list: "",
      swiperOption: {
        grabCursor: true,
        effect: "creative",
        creativeEffect: {
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        on: {
          click: (e) => {
          },
        },
      },
    };
  },
  mounted() {
    this.list = myData.core;
  },
   computed: {
    swiper: function () {
      return this.$refs.informationSwiper.$swiper;
    },
  },
  methods: {
    mouseenter(index) {
      this.count = index;
    },
    toIndex(e){
      this.swiper.slideTo(e, 1000, false);
    }
  },
};
</script>

<style lang="less" scoped>
.con{
  display: flex;
  align-items: center;
  flex-flow: column;
}
.box2 {
  width: 1320px;
  height: 600px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  .swiper-pagination{
    -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
    width: 200px;
    bottom: -50px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    /deep/span{
      height:12px !important;
      width: 12px !important;
    }
    /deep/.swiper-pagination-bullet-active{
      background-color: #fd7a0d !important;
    }
  }
  .swiper {
    width: 1320px;
    height: 690px;
  }
  .topTitle {
    color: #000;
    font-size: 30px;
    width: 200px;
    position: relative;
    margin-bottom: 16px;
    &::after {
      content: " ";
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      bottom: 11px;
      right: 5px;
      transform: rotate(45deg);
      background-color: #fd7a0d;
    }
    &::before {
      content: " ";
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      bottom: 11px;
      left: 5px;
      transform: rotate(45deg);
      background-color: #fd7a0d;
    }
  }
  .boxContent {
    width: 100%;
    display: flex;
    .right {
      flex-shrink: 0;
      width: 500px;
      height: 500px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .left {
      cursor: pointer;
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 60px;
      .leftList {
        text-align: left;
        .title {
          font-size: 20px;
          font-weight: bold;
          text-indent: 2rem;
          position: relative;
          margin-bottom: 24px;
          &::before {
            content: " ";
            position: absolute;
            display: block;
            background-color: #000;
            border-radius: 15px;
            width: 10px;
            height: 10px;
            bottom: 8px;
            left: 5px;
          }
        }
        .titleSon {
          font-size: 18px;
          color: #333;
          margin-bottom: 16px;
        }
        p {
          color: #999;
          font-size: 14px;
          margin-bottom: 32px;
        }
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          display: block;
          border-radius: 15px;
          width: 10px;
          height: 10px;
          border: 4px solid #ffe8d5;
          background-color: #f7f7f7;
          z-index: 300;
          bottom: 30px;
          left: -46px;
        }
        &:nth-child(2) {
          &::before {
            content: " ";
            position: absolute;
            display: block;
            border-radius: 15px;
            width: 1px;
            height: 128px;
            border-left: 2px solid #ffe8d5;
            bottom: 50px;
            left: -38px;
          }
        }
       
        &:nth-last-child(2) {
          &::before {
            content: " ";
            position: absolute;
            display: block;
            border-radius: 15px;
            width: 1px;
            height: 118px;
            border-left: 2px solid #ffe8d5;
            bottom: 50px;
            left: -38px;
          }
        }
         &:nth-child(1){
          &::before {
            content: " ";
            position: absolute;
            display: block;
            border-radius: 15px;
            width: 1px;
            height: 0;
            border-left: 2px solid #ffe8d5;
            bottom: 50px;
            left: -38px;
          }
        }
      }
      .listActive {
        .titleSon,
        .title {
          color: #fd7a0d;
          &::before {
            background-color: #fd7a0d;
          }
        }
        &::after {
          width: 14px;
          height: 14px;
          left: -48px;
          border: 4px solid #fd7a0d;
        }
      }
      .bottom {
        display: flex;
        justify-content: flex-start;
        .more {
          color: #fff;
          padding: 10px 20px;
          border-radius: 60px;
          background-color: #fd7a0d;
        }
      }
    }
  }
}
</style>